import {
	crud as sectionRelationsCrud,
	sectionRelationDetachCheck,
	detachSectionRelation,
	attachSectionRelation,
	getSectionRelationActions,
	getExercises,
} from '@/api/sectionrelations';

const state = {};

const getters = {};

const actions = {
	getSectionRelations(_, params = {}) {
		return sectionRelationsCrud.all(params);
	},
	getSectionRelation(_, sectionRelationId) {
		return sectionRelationsCrud.find(sectionRelationId);
	},
	createSectionRelation(_, { payload }) {
		return sectionRelationsCrud.create(payload);
	},
	updateSectionRelation(_, { sectionRelationId, payload }) {
		return sectionRelationsCrud.update(sectionRelationId, payload);
	},
	deleteSectionRelation(_, { sectionRelationId, payload }) {
		return sectionRelationsCrud.delete(sectionRelationId, payload);
	},
	sectionRelationDetachCheck(_, sectionRelationId) {
		return sectionRelationDetachCheck(sectionRelationId);
	},
	detachSectionRelation(_, sectionRelationId) {
		return detachSectionRelation(sectionRelationId);
	},
	attachSectionRelation(_, { sectionRelationId, payload }) {
		return attachSectionRelation(sectionRelationId, payload);
	},
	getSectionRelationActions(_, sectionRelationId) {
		return getSectionRelationActions(sectionRelationId);
	},
	getExercises(_, { search, courseId, load_books } = {}) {
		return getExercises(search, courseId, load_books);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
