import { crud } from '@/api/essentials';

const state = {};

const getters = {};

const actions = {
	getEssentials(_, params) {
		return crud.all(params);
	},
	getEssential(_, essentialId) {
		return crud.find(essentialId);
	},
	createEssential(_, payload) {
		return crud.create(payload);
	},
	updateEssential(_, { essentialId, payload }) {
		return crud.update(essentialId, payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
