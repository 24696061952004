import { crud, updateWeights } from '@/api/achievements';

const state = () => ({
	achievements: [],
});

const getters = {};

const actions = {
	getAchievements({ commit }, filters = {}) {
		return crud.all(filters).then(({ data }) => {
			commit('setAchievements', data);
			return data.slice(0);
		});
	},

	getAchievement(_, achievementId) {
		return crud.find(achievementId);
	},

	createAchievement(_, payload) {
		return crud.create(payload);
	},

	updateAchievement(_, { achievementId, payload }) {
		return crud.update(achievementId, payload);
	},

	updateWeights(_, payload) {
		return updateWeights(payload);
	},
};

const mutations = {
	setAchievements(state, achievements) {
		state.achievements = achievements;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
