import { crud } from '@/api/featuredachievements';

const state = () => ({
	featuredachievements: [],
});

const getters = {};

const actions = {
	getFeaturedAchievements({ commit }) {
		return crud.all().then(({ data }) => {
			commit('setFeaturedAchievements', data);
			return data.slice(0);
		});
	},

	getFeaturedAchievement(_, featuredAchievementId) {
		return crud.find(featuredAchievementId);
	},

	createFeaturedAchievement(_, payload) {
		return crud.create(payload);
	},

	updateFeaturedAchievement(_, { achievementId, payload }) {
		return crud.update(achievementId, payload);
	},
};

const mutations = {
	setFeaturedAchievements(state, featuredachievements) {
		state.featuredachievements = featuredachievements;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
